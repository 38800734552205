import { render, staticRenderFns } from "./PropertyCardSkeleton.vue?vue&type=template&id=6862d483"
import script from "./PropertyCardSkeleton.vue?vue&type=script&setup=true&lang=ts"
export * from "./PropertyCardSkeleton.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EntityCard: require('/app/components/EntityCard/components/EntityCard.vue').default})
